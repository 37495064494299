<template>
  <div>
    <upcoming-features-update-sidebar
      :sidebar="update_sidebar"
      :feature="sidebar_feature"
      @toggle_sidebar="(val) => (update_sidebar = val)"
      @feature_created="feature_created"
      @feature_updated="feature_updated"
      @feature_deleted="feature_deleted"
    />
    <upcoming-features-count-sidebar :sidebar="count_sidebar" :feature="count_sidebar_feature" @toggle_sidebar="(val) => (count_sidebar = val)" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex align-items-baseline justify-content-center">
          <b-col cols="12" md="12">
            <div class="d-flex align-items-baseline justify-content-end pb-2">
              <b-button variant="flat-primary" @click="initialize()">
                <feather-icon icon="RefreshCwIcon" class="mr-25" />
                <span class="align-middle">Refresh</span>
              </b-button>
              <b-button variant="flat-primary" @click="open_update_sidebar(0)">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span class="align-middle">Add Feature</span>
              </b-button>

              <!-- <b-button variant="flat-primary" @click="user_sidebar = true">
                <feather-icon icon="UserIcon" class="mr-25" />
                <span class="align-middle">User Sidebar</span>
              </b-button> -->
            </div>
          </b-col>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-baseline justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select
              v-model="options.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <!-- <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="options.filter" class="d-inline-block mr-1" placeholder="Search Feature" />
            </div> -->
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="listingtable"
        primary-key="listing_id"
        :items="features"
        :fields="tableColumns"
        :sort-by.sync="options.sortBy"
        :sort-desc.sync="options.sortDesc"
        :current-page.sync="options.currentPage"
        per-page="0"
        :filter="null"
        :api-url="null"
        show-empty
        class="position-relative"
        responsive
        empty-text="No matching records found"
        sticky-header="70vh"
        hover
        :busy="busy"
        head-variant="light"
        no-sort-reset
      >
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <feather-icon icon="EditIcon" class="mr-25" @click="open_update_sidebar(data.item.id)" />
          <feather-icon icon="EyeIcon" class="mr-25" @click="show_feature_count_sidebar(data.item)" />
        </template>
        <!-- Column: actions -->
        <template #cell(liked)="data">
          <b-badge variant="primary" pill class="badge-round">{{ data.item.liked.length }}</b-badge>
        </template>
        <!-- Column: actions -->
        <template #cell(dislike)="data">
          <b-badge variant="primary" pill class="badge-round">{{ data.item.dislike.length }}</b-badge>
        </template>
        <!-- Column: actions -->
        <template #cell(active)="data">
          <feather-icon v-if="data.item.active" icon="CheckCircleIcon" class="mr-25 text-primary" />
          <feather-icon v-else icon="XCircleIcon" class="mr-25 text-warning" />
        </template>
      </b-table>

      <!-- pagination section -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Showing {{ (options.currentPage - 1) * options.perPage }} to {{ (options.currentPage - 1) * options.perPage + options.perPage }} of
              {{ total_features }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="options.currentPage"
              :per-page="options.perPage"
              :total-rows="total_features"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import UpcomingFeaturesUpdateSidebar from "./components/UpcomingFeaturesUpdateSidebar.vue";
import UpcomingFeaturesCountSidebar from "./components/UpcomingFeaturesCountSidebar.vue";

export default {
  name: "UpcomingFeatures",
  components: {
    UpcomingFeaturesUpdateSidebar,
    UpcomingFeaturesCountSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    vSelect,
  },
  data() {
    return {
      features: [],
      tableColumns: [
        {
          key: "name",
          label: "name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "liked",
          label: "liked",
          sortable: true,
          formatter: (v) => v.length,
        },
        {
          key: "dislike",
          label: "disliked",
          sortable: true,
          formatter: (v) => v.length,
        },
        {
          key: "active",
          label: "active",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],

      // table options
      options: {
        currentPage: 1,
        perPage: 100,
        sortBy: "id",
        sortDesc: false,
        filter: "",
      },

      total_features: 0,
      perPageOptions: [50, 100, 150, 200, 250],
      busy: false,

      // sidebar fields
      update_sidebar: false,
      sidebar_feature: null,

      // feature count sidebar
      count_sidebar: false,
      count_sidebar_feature: {},

      // feature count sidebar
      user_sidebar: false,
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.load_features();
      },
    },
  },
  async mounted() {
    try {
      this.initialize();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        this.load_features();
      } catch (error) {
        console.log(error);
      }
    },
    async load_features() {
      try {
        this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_FEATURES}/getfeatures`, { options: this.options });
        this.busy = false;
        if (!res.data.success) throw new Error("features could not be fetched");

        this.features = res.data.features;
        this.total_features = res.data.count;
      } catch (error) {
        this.busy = false;
        console.log(error);
      }
    },

    show_feature_count_sidebar(feature) {
      console.log(feature);
      this.count_sidebar_feature = feature;
      this.count_sidebar = true;
    },
    open_update_sidebar(id) {
      if (!id) {
        this.sidebar_feature = { id, name: "", description: "" };
      } else {
        let index = this.features.findIndex((f) => f.id == id);
        this.sidebar_feature = this.features[index];
      }
      this.update_sidebar = true;
    },
    feature_created(feature) {
      this.features.push(feature);
    },
    feature_updated(feature) {
      let index = this.features.findIndex((f) => f.id === feature.id);
      this.features.splice(index, 1, feature);
    },
    feature_deleted(id) {
      let index = this.features.findIndex((f) => f.id === id);
      this.features.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.link-icons {
  padding-right: 3px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 800;
}
.small-text {
  font-size: 11px;
  font-weight: bold;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
