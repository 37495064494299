<template>
  <b-sidebar
    id="true"
    width="50%"
    :visible="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('toggle_sidebar', val)"
  >
    <template>
      <b-card class="card-transaction" no-body>
        <b-card-header class="d-flex justify-content-between align-items-center">
          <b-card-title class="text-capitalize">User Interaction</b-card-title>
          <div>
            <b-button variant="flat-primary" @click="() => $emit('toggle_sidebar', false)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span class="align-middle">Close</span>
            </b-button>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row class="d-flex justify-content-start align-items-end">
            <!-- name -->
            <b-col cols="12" md="12" class="mb-1">
              <label>Interested Users</label>
              <b-list-group v-if="feature && Object.keys(feature).length">
                <b-list-group-item v-for="(value, index) of feature.liked" :key="index">{{ value }}</b-list-group-item>
                <b-list-group-item v-if="!feature.liked.length">No interaction found</b-list-group-item>
              </b-list-group>
            </b-col>
            <!--  -->
            <b-col cols="12" md="12" class="mb-1">
              <label>Uninterested Users</label>
              <b-list-group v-if="feature && Object.keys(feature).length">
                <b-list-group-item v-for="(value, index) of feature.dislike" :key="index">{{ value }}</b-list-group-item>
                <b-list-group-item v-if="!feature.dislike.length">No interaction found</b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BCard, BCardBody, BButton, BCardTitle, BCardHeader, BRow, BCol, BListGroup, BListGroupItem } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "UpcomingFeaturesCountSidebar",
  components: {
    BSidebar,
    BCard,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    ToastificationContent,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: ["feature", "sidebar"],

  methods: {
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.small-text {
  font-size: 10px;
}
.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}
.w-160p {
  width: 160px;
}
</style>
