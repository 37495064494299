<template>
  <b-sidebar
    id="true"
    width="50%"
    :visible="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('toggle_sidebar', val)"
  >
    <template>
      <b-card class="card-transaction" no-body>
        <b-card-header class="d-flex justify-content-between align-items-center">
          <b-card-title class="text-capitalize">{{ feature_action }} Feature</b-card-title>
          <div>
            <b-button variant="flat-primary" @click="() => $emit('toggle_sidebar', false)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span class="align-middle">Close</span>
            </b-button>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row class="d-flex justify-content-start align-items-end">
            <!-- name -->
            <b-col cols="12" md="12" class="mb-1">
              <label>Feature Name</label>
              <b-form-input v-model="sidebar_feature.name" placeholder="Enter Feature Name" class="w-100"></b-form-input>
            </b-col>
            <!-- description -->
            <b-col cols="12" md="12" class="mb-1">
              <label>Feature Description</label>
              <b-form-textarea v-model="sidebar_feature.description" placeholder="Enter Feature Description" class="w-100"></b-form-textarea>
            </b-col>
            <!-- active -->
            <b-col cols="12" md="12" class="mb-1" v-if="sidebar_feature.id">
              <label>Feature Status</label>
              <b-form-input disabled :value="sidebar_feature.active ? 'Active' : 'Inactive'" class="w-100"></b-form-input>
            </b-col>
            <!-- actions -->
            <b-col cols="12" md="12" class="mt-4 d-flex align-items-center justify-content-end">
              <div>
                <!-- save or update -->
                <b-button
                  v-if="['update', 'save'].includes(feature_action)"
                  class="mr-50 capitalize-text"
                  variant="flat-success"
                  @click="save_feature()"
                  :disabled="feature_action == 'update' && !feature.id"
                >
                  <feather-icon icon="SaveIcon" class="mr-25" />
                  {{ feature_action }}
                </b-button>
                <!-- toggle activation -->
                <b-button v-if="['update'].includes(feature_action)" variant="flat-primary" class="mr-50" @click="toggle_activate()">
                  <feather-icon icon="ToggleLeftIcon" class="mr-25" />
                  {{ sidebar_feature.active ? "Deactivate" : "Activate" }}
                </b-button>

                <!-- Delete Button -->
                <b-button v-if="['update'].includes(feature_action)" variant="flat-danger" @click="delete_features()" :disabled="!feature.id">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span class="align-middle">Delete</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BCard,
  BBadge,
  BCardBody,
  BButton,
  BCardTitle,
  BCardHeader,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BTfoot,
  BLink,
  VBTooltip,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  name: "UpcomingFeaturesUpdateSidebar",
  components: {
    BLink,
    BTbody,
    BTfoot,
    BThead,
    BTr,
    BTh,
    BTd,
    BSidebar,
    BCard,
    BBadge,
    BCardBody,
    BButton,
    BCardTitle,
    BCardHeader,
    BTableSimple,
    ToastificationContent,
    VBTooltip,
    vSelect,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
  },
  props: ["feature", "sidebar"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      sidebar_feature: {
        id: 0,
        name: "",
        description: "",
      },
      default_feature: {
        id: 0,
        name: "",
        description: "",
      },
      request_sent: false,
    };
  },
  computed: {
    feature_action() {
      return this.sidebar_feature.id ? "update" : "save";
    },
  },
  watch: {
    sidebar(show) {
      if (show) this.sidebar_feature = Object.assign({}, this.feature);
      else this.setDefaults();
    },
  },
  async mounted() {
    try {
      this.setDefaults();
    } catch (error) {}
  },
  methods: {
    async save_feature() {
      try {
        if (this.request_sent) return;

        // validating fields
        if (!this.sidebar_feature.name || !this.sidebar_feature.description) {
          return this.showToast("danger", "Error", "Invalid feature name or description");
        }

        // setting values
        let feature = Object.assign({}, this.sidebar_feature);
        let newfeature = feature.id ? false : true;

        // sending request
        this.request_sent = true;
        let res = null;
        if (newfeature) res = await this.$axios.post(`${this.$API_BASE_FEATURES}/createfeature`, { newfeature, feature });
        else res = await this.$axios.post(`${this.$API_BASE_FEATURES}/updatefeature`, { newfeature, feature });
        this.request_sent = false;

        if (!res.data.success) throw new Error(res.data.message);

        if (newfeature) this.$emit("feature_created", res.data.feature);
        else this.$emit("feature_updated", res.data.feature);

        this.showToast("success", "Success", "Features Updated");
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Features could not be updated");
      }
    },
    async delete_features() {
      try {
        if (this.request_sent) return;
        if (!this.feature.id) return;

        this.request_sent = true;
        let id = this.feature.id;
        let res = await this.$axios.post(`${this.$API_BASE_FEATURES}/deletefeature`, { id });
        this.request_sent = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.$emit("feature_deleted", res.data.feature_id);
        this.setDefaults();
        this.showToast("success", "Success", "Feature removed successfully");
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.showToast("danger", "Error", "Feature could not be removed");
      }
    },
    async toggle_activate() {
      try {
        if (this.request_sent) return;
        if (!this.feature.id) return;

        this.request_sent = true;
        let id = this.feature.id;
        let res = await this.$axios.post(`${this.$API_BASE_FEATURES}/toggleactivate`, { id });
        this.request_sent = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.$emit("feature_updated", res.data.feature);
        this.sidebar_feature = res.data.feature;
        this.showToast("success", "Success", "Feature updated successfully");
      } catch (error) {
        this.request_sent = false;
        console.log(error);
        this.showToast("danger", "Error", "Feature could not be updated");
      }
    },
    setDefaults() {
      this.sidebar_feature = Object.assign({}, this.default_feature);
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.capitalize-text {
  text-transform: capitalize;
}
.small-text {
  font-size: 10px;
}
.width-30 {
  width: 30%;
}
.width-70 {
  width: 70%;
}
.w-160p {
  width: 160px;
}
</style>
